.parentContainer {
    position: relative;
    background-image: linear-gradient(#e8163e 60%, #9d0d50);
    padding: clamp(33px, 5.2vw, 100px) 0px;
    font-family: var(--font-primary);
    /* height: clamp(400px, 31.2vw, 600px); */
}

.bgImages {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_image_left.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_image_right.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/review_top.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/red_bottom.png");
    opacity: 0.6;
    background-repeat: no-repeat;
    background-position: 0% 0%, 100% 100%, 0 0, 100% 100%;
}


.waterMarkAndTextContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.waterMarkNoWrap {
    z-index: 0;
    left: calc(clamp(50px, 12.08vw, 232.4px)*-1);
    position: absolute;
    -webkit-text-stroke: 1.5px hsla(0, 0%, 100%, 0.2);
    -webkit-background-clip: text;
    font-stretch: 0%;
    -webkit-text-fill-color: transparent;
    paint-order: stroke fill;
    font-size: clamp(72px, 11vw, 230px);
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    text-wrap: nowrap;
    word-wrap: none;
    opacity: 0.4;
    white-space: nowrap;
}

.subHeading {
    font-size: clamp(14px, 1.67vw, 32px);
    font-weight: 500;
    white-space: pre-line;
    line-height: 1.3;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    color: #ffff00;
    margin-bottom: 0px;
    text-align: center;
}

.heading {
    position: relative;
    font-size: clamp(20px, 2.7vw, 52px);
    white-space: pre-line;
    z-index: 2;
    font-weight: 800;
    font-stretch: normal;
    text-align: center;
    line-height: 1.22;
    color: #fff;
}

.mainContainer {
    position: relative;
    background-color: #fff;
    margin-top: clamp(25px, 3.2vw, 60px);
    margin-bottom: clamp(-400px, -20.8vw, -200px);
    width: 100%;
    z-index: 10;
    border-radius: clamp(4px, 1.25vw, 24px);
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.24);
    padding: clamp(20px, 2.1vw, 40px);
}

.ratingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ratingNumber {
    font-size: clamp(52px, 6.25vw, 120px);
    font-weight: 900;
    text-align: center;
    color: #1e092d;
}

.ratingStarContainer {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: space-evenly;
}

.ratingStars {
    position: relative;
    width: clamp(25px, 2.6vw, 50px);
    height: clamp(25px, 2.6vw, 50px);
}

.ratingDesc {
    margin: 10px;
    font-size: clamp(14px, 1.1vw, 22px);
    font-weight: 500;
    text-align: center;
    color: rgba(30, 9, 45, 0.4);
}

.reviewParentContainer {
    margin: auto;
    width: calc(100vw);
    margin-left: calc(-50vw + 50%);
}

.cardContainer {
    background-color: #f6f5f5;
    padding: clamp(12px, 1.56vw, 30px);
    border-radius: 8px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(207, 205, 205, 0.8);
    height: clamp(180px, 18.25vw, 350px);
    margin: 10px;
    transition: all 0.5s ease;
}

.cardMainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cardHeadingContainer {
    display: flex;
    align-items: flex-start;
}

.profileContainer {
    position: relative;
    width: clamp(30px, 3.2vw, 60px);
    height: clamp(30px, 3.2vw, 60px);
    border-radius: 50%;
    overflow: hidden;
}

.nameContainer {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    align-items: flex-start;
    flex: 1;
}

.nameContainer p {
    margin: 0;
}

.userName {
    font-size: clamp(14px, 1.35vw, 26px);
    font-weight: 600;
    color: #1e092d;
}

.reviewDate {
    font-size: clamp(10px, 1vw, 18px);
    color: rgba(30, 9, 45, 0.4);
}

.starsContainer {
    display: flex;
    padding-top: 5px;
}

.userRatingStars {
    position: relative;
    width: clamp(14px, 1.25vw, 25px);
    height: clamp(14px, 1.25vw, 25px);
}

.descContainer {
    padding-top: clamp(10px, 1vw, 18px);
    font-size: clamp(12px, 1.04vw, 20px);
    line-height: 1.51;
    color: #280042;
    flex: 1;
    margin: 0;
}

.summary {
    font-size: clamp(10px, 1vw, 18px);
    color: rgba(30, 9, 45, 0.4);
    margin: 0;
}

.iconParentContainer {
    position: absolute;
    width: clamp(35px, 4.7vw, 90px);
    height: clamp(65px, 8.85vw, 170px);
    background-color: rgba(217, 217, 217, 0.6);
    top: 0;
    right: 5%;
    border-bottom-left-radius: clamp(10px, 1vw, 20px);
    border-bottom-right-radius: clamp(10px, 1vw, 20px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconContainer {
    width: 86%;
    height: 90%;
    position: relative;
    display: flex;
    padding: 8px;
}

@media screen and (max-width:700px) {
    .bgImages {
        background-size: 70%, 0, 60%, 0%;
        background-position: 0 0;
    }

    .ratingStarContainer {
        width: 60%;
    }
}