.parentContainer {
    position: relative;
    background-image: linear-gradient(#5b0ece, #290245);
    padding: clamp(22px, 4.17vw, 80px) 0px;
    font-family: var(--font-primary);
}

.bgImages {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/box_cross.webp"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_image_left.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_image_right.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/box_cross.webp");
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: 5% 90%, 0% 0%, 100% 100%, 90% 5%;
}


.waterMarkAndTextContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.waterMarkNoWrap {
    z-index: 0;
    left: calc(clamp(50px, 12.08vw, 232.4px)*-1);
    position: absolute;
    -webkit-text-stroke: 1.5px hsla(0, 0%, 100%, 0.2);
    -webkit-background-clip: text;
    font-stretch: 0%;
    -webkit-text-fill-color: transparent;
    paint-order: stroke fill;
    font-size: clamp(72px, 11vw, 230px);
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    text-wrap: nowrap;
    word-wrap: none;
    opacity: 0.4;
    white-space: nowrap;
}

.subHeading {
    font-size: clamp(14px, 1.67vw, 32px);
    font-weight: 500;
    white-space: pre-line;
    line-height: 1.3;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    color: #ffff00;
    margin-bottom: 0px;
}

.heading {
    position: relative;
    font-size: clamp(20px, 2.7vw, 52px);
    white-space: pre-line;
    z-index: 2;
    font-weight: 800;
    font-stretch: normal;
    line-height: 1.22;
    color: #fff;
    width: 95%;
    margin-bottom: 0px;
}

.snapshotImgContainer {
    position: absolute;
    z-index: 2;
    top: -5%;
    right: 0;
    width: clamp(330px, 38.5vw, 650px);
    height: clamp(218px, 25.3vw, 406px);
}

.descContainer {
    margin-top: clamp(20px, 3.12vw, 60px);
    color: #fff;
    font-size: clamp(12px, 1.25vw, 24px);
    max-width: 45%;
    opacity: 0.8;
}

.pointsHeading {
    font-size: clamp(14px, 1.45vw, 28px);
    font-weight: bold;
    color: #fff;
    margin-top: clamp(10px, 2.2vw, 50px);
    margin-bottom: clamp(10px, 1.6vw, 30px);
}

.pointsItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.pointsContainer {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: clamp(15px, 1vw, 20px) clamp(6px, 3.5vw, 70px) clamp(15px, 1vw, 20px) clamp(6px, 1vw, 18px);
    width: clamp(150px, 17.7vw, 340px);
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
}

.pointImageContainer {
    min-width: clamp(38px, 3.55vw, 68px);
    height: clamp(38px, 3.55vw, 68px);
    position: relative;
}

.pointDesc {
    margin: 0px 0px 0px clamp(12px, 1.25vw, 25px);
    font-weight: 500;
    opacity: 0.8;
    color: #fff;
    line-height: 1.5;
    font-size: clamp(12px, 1vw, 20px);
}

.pointDescMob {
    display: none;
}

@media screen and (max-width: 700px) {
    .waterMarkAndTextContainer {
        margin-top: 2%;
    }

    .parentContainer {
        background-image: linear-gradient(#3f1597, #200b4c);
    }

    .bgImages {
        background-size: 0, 70%, 75%, 0;
        background-position: 0, 0 0, 150% 250%, 0;
    }

    .heading {
        text-align: center;
        margin: auto;
    }

    .subHeading {
        text-align: center;
    }

    .snapshotImgContainer {
        position: relative;
        margin: 10px auto;
    }

    .descContainer {
        max-width: 90%;
        text-align: center;
        margin: auto;
    }

    .pointsHeading {
        text-align: center;
    }

    .pointDesc {
        display: none;
    }

    .pointDescMob {
        display: block;
        margin: 0;
    }

    .pointsContainer {
        width: 48%;
    }

}


@media screen and (min-width: 2000px) {
    .snapshotImgContainer {
        right: -10%;
    }

    .descContainer {
        max-width: 50%;
    }
}