.parentContainer {
    position: relative;
    padding-top: clamp(30px, 2.6vw, 50px);
    font-family: var(--font-primary);
}

.bgImages {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_white_left.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_white_right.png");
    background-repeat: no-repeat;
    background-position: 0% 0%, 100% 100%;
}

.waterMarkAndTextContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: clamp(30px, 3.12vw, 60px);
}

.waterMarkNoWrap {
    z-index: -1;
    left: calc(clamp(50px, 12.08vw, 232.4px)*-1);
    position: absolute;
    -webkit-text-stroke: 1.5px transparent;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 45%, rgba(0, 0, 0, 0) 95%);
    -webkit-background-clip: text;
    font-stretch: 0%;
    -webkit-text-fill-color: #fff;
    paint-order: stroke fill;
    font-size: clamp(72px, 11vw, 230px);
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    text-wrap: nowrap;
    word-wrap: none;
    opacity: 0.4;
    white-space: nowrap;
}

.subHeading {
    font-size: clamp(12px, 1.67vw, 32px);
    font-weight: 500;
    white-space: pre-line;
    line-height: 1.3;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    text-align: center;
    color: #e3425b;
    margin-bottom: 0px;
}

.heading {
    position: relative;
    font-size: clamp(20px, 2.7vw, 52px);
    white-space: pre-line;
    z-index: 2;
    text-align: center;
    font-weight: 800;
    font-stretch: normal;
    line-height: 1.35;
    color: #1e092d;
    width: 95%;
    margin: auto;
}

.descContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imgContainer {
    position: relative;
    height: clamp(52px, 6.25vw, 120px);
    width: clamp(72px, 8.1vw, 156px);
}

.content {
    padding: clamp(10px, 1.5vw, 30px) 0px;
    text-align: center;
}

.text1 {
    color: rgba(30, 9, 45, 0.8);
    font-size: clamp(12px, 1.87vw, 36px);
    font-weight: 900;
    font-style: italic;
    margin: 0;
}

.text2 {
    font-size: clamp(12px, 1.25vw, 24px);
    color: rgba(30, 9, 45, 0.6);
    font-weight: bold;
    font-style: italic;
}


@media screen and (max-width: 700px) {
    .waterMarkAndTextContainer {
        margin-top: 2%;
    }

    .bgImages {
        background-size: 70%, 75%;
        background-position: 0 0, 150% 250%;
    }

    .text1 {
        color: #1e092d;
        font-weight: bold;
        font-style: normal;
        margin: 0;
        line-height: 9px;
    }

    .text2 {
        color: #1e092d;
        font-weight: bold;
        font-style: normal;
    }
}