.floatingDownloadButtonContainer {
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background-image: linear-gradient(to top, #1e092d 24%, rgba(30, 9, 45, 0.93) 49%, rgba(30, 9, 45, 0.8) 62%, rgba(30, 9, 45, 0.55) 81%, rgba(30, 9, 45, 0) 100%);
    font-family: var(--font-primary);
}

.downloadParentContainer {
    padding: 30px 20px 20px;
}

.floatingDownloadButton {
    position: fixed;
    top: 50px;
    left: 0;
    height: calc(100% - 126px);
    width: 100%;
    background-color: #fff;
    z-index: 1;
}

.floatingDownloadButtonContainerStory .floatingDownloadButton {
    height: calc(100% - 160px);
}

.floatingDownloadButtonFB {
    height: fit-content;
}

.offerPopupClose {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
}

.videoView {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center
}

.videoViewFB {
    height: 390px;
}

.videoSoundControlView {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
    background-color: #ccc;
    padding: 2px
}
.videoSoundControlViewForStory {
    position: absolute;
    top: 32px;
    right: 50px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
    background-color: #ccc;
    padding: 2px
}

.storyBarContainer {
    display: flex;
    grid-gap: 5px;
    justify-content: space-between;
    position: absolute;
    z-index: 9;
    margin: 2%;
    width: 96%;
}

.complete {
    background: #fff;
}

.active {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    animation: fill 3s linear;
}

.storyBar {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background: #ffffff4c;
    position: relative;
    overflow: hidden;
}

@keyframes fill {
    from {
        transform: translateX(-100%)
    }

    to {
        transform: translateX(0%)
    }
}

.storyAnimationRunning {
    animation-play-state: running;
}

.storyAnimationPaused {
    animation-play-state: paused;
}

.howToInstallLabel {
    position: absolute;
    z-index: 10;
    color: white;
    top: 30px;
    left: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--font-primary);
    text-shadow: 0 2px 0px rgba(0, 0, 0, 1);
}

.pageBottomContainer {
    position: relative;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageParentContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.linksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.linksContainer a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floatingParentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.floatingImgContainer {
    position: relative;
    width: 19px;
    height: 23px;
}

.floatingParentContainer p {
    font-size: 10px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}

@keyframes blinking {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@media screen and (min-width: 767px) {
    .floatingDownloadButtonContainer {
        display: none !important;
    }
}

@media screen and (max-width: 350px) {
    .downloadParentContainer {
        padding: 30px 10px;
    }
}