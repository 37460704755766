.parentContainer {
    position: relative;
    padding-top: 15px;
    font-family: var(--font-primary);
    background-color: #1e092d;
}

.heading {
    font-size: clamp(10px, 1.3vw, 26px);
    font-weight: normal;
    text-align: center;
    line-height: 0.85;
    color: rgba(255, 255, 255, 0.8);
}

.imageContainer {
    position: relative;
    width: auto;
    height: clamp(40px, 4.6vw, 90px);
}

.bottomContainer {
    position: relative;
    z-index: 2;
    height: clamp(12px, 1.1vw, 22px);
    background: linear-gradient(to top, #cd93ff, #3a0e59, #1e092d);
    clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
    margin-top: -2px;
}

.topContainer {
    display: none;
}

@media screen and (max-width: 767px) {
    .topContainer {
        display: block;
        height: 15px;
        background-image: url(https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/outline_payment_top.png),
            linear-gradient(to bottom, rgba(30, 9, 45, 0) 5%, rgba(30, 9, 45, 1) 6%, rgba(30, 9, 45, 1) 100%);
        background-repeat: no-repeat;
        background-size: contain;
        clip-path: polygon(6.7% 0, 11% 75%, 86% 75%, 91% 0, 100% 0, 100% 100%, 0 100%, 0 0);
        margin-top: -14px;
        margin-bottom: -1px;
    }
}