.root {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: clamp(28px, 3.125vw, 60px);
  align-items: center;
  justify-content: center;
  gap: clamp(16px, 2.08vw, 40px);
}


.dotsContainer {
  position: relative;
  overflow: hidden;
  width: clamp(70px, 8.33vw, 160px);
  display: block;
  height: clamp(8px, 0.83vw, 16px);
}

.dot {
  position: relative;
  display: inline-block;
  opacity: 0.2;
  border-radius: 20px;
  background-color: #fff;
  padding: 0px;
  width: clamp(6px, 0.625vw, 12px);
  height: clamp(6px, 0.625vw, 12px);
  ;

}


.dotsContainer>ul {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  position: absolute;
  left: 0px;
  top: 0px;
  gap: clamp(4px, 0.625vw, 12px);
  transition: all ease 1s;
  align-items: center;
}

.dotsContainer>ul>li {
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  height: clamp(8px, 0.83vw, 16px);
  min-width: clamp(8px, 0.83vw, 16px);
}

.dotsContainer>ul>li>span {
  transition: all ease 0.5s;
}


.active {
  width: clamp(23px, 2.5vw, 48px);
  height: clamp(8px, 0.83vw, 16px);
  opacity: 1;
}

.nextDot,
.prevDot {
  opacity: 0.5;
  width: clamp(8px, 0.83vw, 16px);
  height: clamp(8px, 0.83vw, 16px);
}

.zoVenturesGallerySlickArrow {
  z-index: 1;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.zoVenturesGallerySlickArrow.prev {
  transform: rotate(180deg);
}

.arrowImage {
  position: relative;
  width: clamp(12px, 1.6vw, 32px);
  height: clamp(12px, 1.6vw, 32px);
  background-color: #fff;
  margin: clamp(4.6px, 0.66vw, 13px) clamp(4.6px, 0.66vw, 13px);
  clip-path: polygon(30% 0%, 60% 0%, 90% 50%, 60% 100%, 30% 100%, 55% 50%);
}

.customArrowImage {
  position: relative;
  width: clamp(40px, 4.1vw, 80px);
  height: clamp(40px, 4.1vw, 80px);
  margin: clamp(4.6px, 0.66vw, 13px) clamp(4.6px, 0.66vw, 13px);
}