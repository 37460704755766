.parentContainer {
    position: relative;
    background-color: #f6f6fb;
    padding: clamp(22px, 4.17vw, 80px) 0px;
    padding-top: clamp(200px, 20vw, 420px);

    font-family: var(--font-primary);
}

.bgImages {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_white_left.png"),
        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/dots_white_right.png");
    background-repeat: no-repeat;
    background-position: 0% 0%, 100% 100%;
}

.heading {
    font-size: clamp(14px, 1.67vw, 32px);
    margin-bottom: clamp(16px, 3.3vw, 70px);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #1e092d;
}

.sliderParentContainer {
    margin-top: 20px;
    width: 100%;
}

.sliderContainer {
    margin: 0 clamp(8px, 1.04vw, 20px) 15px;
    transition: all 300ms;
    height: clamp(245px, 22.3vw, 440px);
    width: clamp(180px, 17.2vw, 330px);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: clamp(8px, 0.7vw, 12px);
}

.centerContainer {
    height: clamp(245px, 26vw, 500px);
    width: clamp(180px, 19.4vw, 373px);
    margin-left: 0;
    margin-right: 0;
}

.sliderContainer .text {
    position: absolute;
    width: 60%;
    left: 0;
    bottom: 0;
    z-index: 200;
    padding: 0px 10px;
}

.sliderContainer .text p {
    text-align: left;
    font-size: clamp(6px, 0.72vw, 14px);
    font-weight: normal;
    color: #fff;
    font-style: italic;
    margin-top: 0;
}

.sliderContainer .text h3 {
    text-align: left;
    font-size: clamp(8px, 1.25vw, 24px);
    font-weight: 800;
    color: #fff;
    font-style: italic;
    margin: 0;
}

.sliderContainer h3 {
    text-align: center;
    font-size: clamp(14px, 2.08vw, 40px);
    font-weight: bold;
    color: #fff;
}

.sliderContainer p {
    text-align: center;
    font-size: clamp(10px, 1.25vw, 24px);
    font-weight: normal;
    color: #fff;
}

.playButton {
    height: clamp(30px, 3.12vw, 60px);
    width: clamp(30px, 3.12vw, 60px);
    position: relative;
}

.influencerName {
    position: absolute;
    color: #fff;
    font-weight: 800;
    font-size: clamp(14px, 1.33vw, 26px);
    left: 5%;
    bottom: 8%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.influencerDesc {
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: clamp(9px, 1vw, 20px);
    left: 5%;
    bottom: 3%;
    text-overflow: ellipsis;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    width: 95%;
}


@media screen and (max-width: 700px) {
    .heading {
        color: #280042;
    }

    .bgImages {
        background-size: 70%, 75%;
        background-position: 0 0, 150% 250%;
    }

    .centerDiv {
        width: fit-content;
        margin: auto;
    }
}