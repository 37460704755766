.arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    z-index: 100;
    width: clamp(40px, 4.1vw, 80px);
    height: clamp(40px, 4.1vw, 80px);
}

.prevArrow {
    left: -5%;
    transform: rotate(180deg);
}

.nextArrow {
    right: -5%;
}

@media screen and (max-width:700px) {
    .prevArrow {
        left: -2%;
    }

    .nextArrow {
        right: -2%;
    }
}

@media screen and (min-width: 2000px) {
    .prevArrow {
        left: -10%;
    }

    .nextArrow {
        right: -10%;
    }
}