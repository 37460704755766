.root {
    position: relative;
    font-family: var(--font-primary);
}

.wedgeTop {
    width: 100vw;
    display: none;
    height: clamp(10px, 2vw, 38px);
    background-color: rgba(111, 47, 185, 0.24);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.wedgeBottom {
    width: 100vw;
    display: none;
    height: clamp(10px, 2vw, 38px);
    background-color: rgba(111, 47, 185, 0.24);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
}

.mainContainer {
    padding: clamp(10px, 3.69vw, 71px) 16px;
    display: flex;
    flex-direction: column;
    gap: clamp(16px, 5.67vw, 109px);
}


.waterMarkAndTextContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.waterMarkNoWrap {
    z-index: -1;
    left: calc(clamp(50px, 12.08vw, 232.4px)*-1);
    position: absolute;
    -webkit-text-stroke: 1.5px transparent;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 45%, rgba(0, 0, 0, 0) 95%);
    -webkit-background-clip: text;
    font-stretch: 0%;
    -webkit-text-fill-color: #fff;
    paint-order: stroke fill;
    font-size: clamp(72px, 11vw, 230px);
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    text-wrap: nowrap;
    word-wrap: none;
    opacity: 0.4;
    white-space: nowrap;
}

.subHeading {
    font-size: clamp(14px, 1.67vw, 32px);
    font-weight: 500;
    white-space: pre-line;
    line-height: 1.3;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    text-align: center;
    color: #e3425b;
    margin-bottom: 0px;
}

.heading {
    position: relative;
    font-size: clamp(24px, 2.7vw, 52px);
    white-space: pre-line;
    z-index: 2;
    text-align: center;
    font-weight: 800;
    font-stretch: normal;
    line-height: 1.35;
    color: #1e092d;
    width: 95%;
    margin: auto;
}

.contentsContainer {
    display: flex;
    justify-content: space-between;
    height: clamp(200px, 19.2vw, 370px);
    min-height: fit-content;
}

.faqParentContainer {
    /* padding: 50px 0px; */
    border: solid 1px #c2aee7;
    padding: 0;
}

.faqContainer {
    position: relative;
    border: solid 0.5px #c2aee7;
    background-color: #efebf7;
}

.faqContainer:has(.input:checked) {
    background: #ffffff;
}

.input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
}

.h3 {
    color: #ffffff;
    padding: 1vw 3vw;
    position: relative;
    font-size: clamp(12px, 1.3vw, 30px);
    font-weight: 500;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.label {
    cursor: pointer;
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
}

.label span {
    font-size: clamp(12px, 1.25vw, 24px);
    font-weight: 800;
    color: #3c005b;
    min-width: 10px;
    margin-right: 2vw;
}

.para {
    max-height: 0;
    overflow: hidden;
}

.para p {
    font-size: clamp(12px, 1.25vw, 24px);
    font-weight: 100;
    color: #000;
    padding: 0 4vw 1vw;
}

.para ul {
    margin-left: 100px;
}

.para li {
    font-size: 20px;
    font-weight: 300;
    color: #000;
    padding: 0% 2% 1%;
}

.h3::after {
    content: "";
    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/faq-plus-image.webp") no-repeat;
    background-size: contain;
    width: clamp(12px, 2.3vw, 45px);
    height: 100%;
    position: absolute;
    right: 1%;
    top: 20%;
    opacity: 0.7;
}

.input:checked~label h3 {
    color: #000000;
    font-weight: 600;
}

.input:checked~label span {
    color: #000000;
}

.input:checked~label h3::after {
    content: "";
    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/collapsed-minus_v2.svg") no-repeat;
    background-size: contain;
    filter: invert(0%);
    width: clamp(12px, 2.3vw, 45px);
    position: absolute;
    right: 1%;
    top: 50%;
    opacity: 0.7;
}

.faqTitle {
    margin: 0 18px 0 0;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3c005b;
}

.input:checked~div.para {
    max-height: 100vh;
}

@media screen and (width <=767px) {
    .subHeading {
        margin-bottom: 13px;
    }

    .waterMarkAndTextContainer {
        margin-top: 5%;
    }

    .waterMarkNoWrap {
        font-size: clamp(72px, 23vw, 230px);
    }

    .logoConatiner {
        width: 70%;
    }

    .wedgeBottom {
        display: none;
    }

    .faqParentContainer {
        margin: 16px;
    }

    .faqContainer {
        padding: 5px;
    }

    .label span {
        font-weight: 600;
    }

    .input:checked~label h3::after {
        top: 50%;
        right: 2%;
    }

    .input~label h3::after {
        top: 20%;
        right: 2%;
    }

    .para p {
        padding: 0 7vw;
    }

    .para ul {
        margin-left: 0;
    }

    .para li {
        font-size: 12px;
    }

    .input:checked~div.para {
        height: fit-content;
    }

    .h3 {
        color: #ffffff;
        padding: 1vw 3vw;
        position: relative;
        font-size: clamp(12px, 3.8vw, 30px);
    }

    .h3::after {
        width: clamp(12px, 5vw, 45px);
    }

    .input:checked~label h3::after {
        width: clamp(12px, 5vw, 45px);
    }

    .wedgeTop {
        display: block;
    }

    .wedgeBottom {
        display: block;
        margin-bottom: -3px;
    }
}

@media screen and (max-width: 576px) {
    .faqContainer {
        position: relative;
        border: solid 0.5px #c2aee7;
        background-color: #efebf7;
    }
}